import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const inputStyle = {
    padding: "10px",
    margin: "0 0 20px 0", // Adjusted for consistency and alignment
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
    width: "calc(100% - 22px)", // Adjust width to account for border and padding
    boxSizing: "border-box", // Ensures padding and border are included in width calculation
  };

  const handleRequestOtp = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/forgotpassword`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ emailOrPhone: email }),
      }
    );
    const data = await response.json();
    console.log(data);
    setLoading(false);
    if (response.ok) {
      navigate("/verifyotp", { state: { email } });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "50vw",
        marginLeft: "250px",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          borderRadius: "8px",
          background: "white",
          boxSizing: "border-box",
        }}
      >
        <form
          onSubmit={handleRequestOtp}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h1 style={{ textAlign: "center" }}>Password assistance</h1>
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "inline-block", // Makes label inline with input
                width: "100%",
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Email or mobile phone number:
            </label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyle}
              required
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            style={{
              padding: "12px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              opacity: loading ? 0.7 : 1,
              pointerEvents: loading ? "none" : "auto",
              transition: "background 0.3s",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#45a049")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#4CAF50")
            }
          >
            {loading ? "Loading..." : "Continue"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
