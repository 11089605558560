import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const SignUp = () => {
  const [username, setUsername] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    axios
      .post(`${process.env.REACT_APP_API_URL}/SignUp`, {
        username,
        email,
        password,
      })
      .then((response) => {
        console.log(response.data);
        navigate("/Login"); // Navigate to the login page
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response error:", error.response);
          setError(
            error.response.data.message ||
              "Something went wrong with the response"
          );
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          console.error("Request error:", error.request);
          setError(
            "The request was made but no response was received. This usually indicates a network error, such as a timeout, dropped connection, or the server is not reachable."
          );
        } else if (error.message === "Network Error") {
          // Network error, this is triggered when there is no internet connection
          // or the request couldn't be made for some reason
          console.error("Network error:", error.message);
          setError(
            "A network error occurred. This could be due to a loss of connection or the server being unreachable."
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
          setError("An error occurred: " + error.message);
        }
      });
  };
  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "140%",
    height: "100vh",
  };
  const formContainerStyle = {
    opacity: 0.9,
    width: "100%",
    padding: "20px",
    borderRadius: "15px",
    background: "white",
    marginLeft: "500px",
  };
  const inputStyle = {
    height: "45px",
    fontSize: "1em",
    width: "100%",
  };
  return (
    <div style={wrapperStyle}>
      <Container style={formContainerStyle}>
        <Form onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form.Group>
            <center>
              <h3 className="card-title text-center mb-4">SignUp</h3>
            </center>
            <Form.Label>UserName</Form.Label>
            <Form.Control
              type="text"
              name="username"
              placeholder="UserName"
              onChange={(e) => setUsername(e.target.value)}
              required
              style={inputStyle}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              style={inputStyle}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              required
              style={inputStyle}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Sign Up
          </Button>
          <br />
          <p>Already have an account? Login here</p>
          <Link
            to="/login"
            className="btn btn-default border w-100 bg-light rounded-0 text-decordation-none"
          >
            Login
          </Link>
        </Form>
      </Container>
    </div>
  );
};
export default SignUp;
