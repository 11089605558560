import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CommentForm.css";
import { addCommentSuccess } from "../../redux/actions";

function CommentForm({ itemId, onCommentSubmitted }) {
  const [manualComment, setManualComment] = useState("");
  const dispatch = useDispatch();

  const dateRange = useSelector(
    (state) => state.comments.dateRange || { start: null, end: null }
  );

  useEffect(() => {
    if (dateRange.start) {
      submitAutoComment("start", dateRange.start);
    }
  }, [dateRange.start]);

  useEffect(() => {
    if (dateRange.end) {
      submitAutoComment("end", dateRange.end);
    }
  }, [dateRange.end]);

  const submitAutoComment = async (type, date) => {
    const formattedDate = new Date(date).toLocaleString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const commentText = `${
      type === "start" ? "Start" : "End"
    } Date selected : ${formattedDate}`;
    submitComment(commentText);
  };

  const submitComment = async (commentText) => {
    // Trim the comment text to ensure it's not just whitespace
    console.log("Submitting comment:", commentText);
    if (!commentText.trim() || commentText === "<p><br></p>") {
      console.log("Comment is empty or just whitespace. Not submitting.");
      return; // Do not submit if the comment is empty or just whitespace
    }

    const username = localStorage.getItem("username") || "Anonymous";
    const commentPayload = {
      text: commentText.trim(), // Trim the text before sending
      postedBy: username,
    };

    try {
      const response = await fetch(`/api/items/${itemId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(commentPayload),
      });

      if (response.ok) {
        const newComment = await response.json();
        dispatch(addCommentSuccess(itemId, newComment));
        if (onCommentSubmitted) onCommentSubmitted(newComment);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleManualSubmit = (event) => {
    event.preventDefault();
    const trimmedComment = manualComment.trim();
    console.log("Manual submit:", trimmedComment);
    if (trimmedComment && trimmedComment !== "<p><br></p>") {
      submitComment(trimmedComment);
      setManualComment(""); // Clear the manual comment input after submission
    } else {
      alert("Comment cannot be empty or just whitespace");
    }
  };

  useEffect(() => {
    const quillEditor = document.querySelector(".ql-editor");
    if (quillEditor) {
      quillEditor.style.whiteSpace = "pre-wrap";
      quillEditor.style.wordWrap = "break-word";
      quillEditor.style.overflowWrap = "break-word";
      quillEditor.style.wordBreak = "break-all"; // Ensure long URLs break
    }
  }, []);

  return (
    <form className="comment-form" onSubmit={handleManualSubmit}>
      <ReactQuill
        value={manualComment}
        onChange={setManualComment}
        placeholder="Add a comment"
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "link",
          "image",
          "video",
        ]}
        className="react-quill-container"
      />
      <button className="button-fullwidth" type="submit">
        Submit
      </button>
    </form>
  );
}

export default CommentForm;

// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "./CommentForm.css";
// import { addCommentSuccess } from "../../redux/actions";

// function CommentForm({ itemId, onCommentSubmitted }) {
//   const [manualComment, setManualComment] = useState("");
//   const dispatch = useDispatch();

//   const dateRange = useSelector(
//     (state) => state.comments.dateRange || { start: null, end: null }
//   );

//   // Handle automatic updates for start date changes
//   useEffect(() => {
//     if (dateRange.start) {
//       submitAutoComment("start", dateRange.start);
//     }
//   }, [dateRange.start]);

//   // Handle automatic updates for end date changes
//   useEffect(() => {
//     if (dateRange.end) {
//       submitAutoComment("end", dateRange.end);
//     }
//   }, [dateRange.end]);

//   // Function to format date and submit automatic comment
//   const submitAutoComment = async (type, date) => {
//     const formattedDate = new Date(date).toLocaleString("en-GB", {
//       year: "numeric",
//       month: "numeric",
//       day: "numeric",
//       // hour: "2-digit",
//       // minute: "2-digit",
//       // second: "2-digit",
//       // hour12: true,
//     });
//     const commentText = `${
//       type === "start" ? "Start" : "End"
//     } Date selected : ${formattedDate}`;
//     submitComment(commentText);
//   };

//   // General function to submit comments
//   const submitComment = async (commentText) => {
//     const username = localStorage.getItem("username") || "Anonymous";
//     const commentPayload = {
//       text: commentText,
//       postedBy: username,
//     };

//     try {
//       const response = await fetch(`/api/items/${itemId}/comments`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(commentPayload),
//       });

//       if (response.ok) {
//         const newComment = await response.json();
//         dispatch(addCommentSuccess(itemId, newComment));
//         if (onCommentSubmitted) onCommentSubmitted(newComment);
//       } else {
//         throw new Error("Network response was not ok");
//       }
//     } catch (error) {
//       console.error("Error submitting comment:", error);
//     }
//   };

//   // Handle manual comment submission
//   const handleManualSubmit = (event) => {
//     event.preventDefault();
//     if (manualComment.trim()) {
//       submitComment(manualComment);
//       setManualComment(""); // Clear the manual comment input after submission
//     }
//   };

//   return (
//     <form onSubmit={handleManualSubmit}>
//       <ReactQuill
//         value={manualComment}
//         onChange={setManualComment}
//         placeholder="Add a comment"
//       />
//       <button className="button-fullwidth" type="submit">
//         Submit
//       </button>
//     </form>
//   );
// }

// export default CommentForm;
