import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NewPasswordChanges() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { emailOrPhone } = location.state || {};

  const inputStyle = {
    padding: "10px",
    margin: "0 0 20px 0",
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
    width: "calc(100% - 22px)",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    padding: "12px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background 0.3s",
    pointerEvents: "auto",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (password.length < 6) {
      alert("Password must be at least 6 characters.");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/newpasswordchanges`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          emailOrPhone: emailOrPhone,
          newPassword: password,
        }),
      }
    );

    const data = await response.json();
    console.log(data);
    if (response.ok) navigate("/"); // Redirect to sign-in page or dashboard
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "50vw",
        marginLeft: "250px",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          borderRadius: "8px",
          background: "white",
          boxSizing: "border-box",
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h2 style={{ textAlign: "center" }}>Create new password</h2>
          <label
            style={{
              display: "block",
              marginBottom: "20px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            New password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyle}
              required
            />
          </label>
          <label
            style={{
              display: "block",
              marginBottom: "20px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Confirm password:
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={inputStyle}
              required
            />
          </label>
          <button type="submit" style={buttonStyle}>
            Save changes and sign in
          </button>
        </form>
      </div>
    </div>
  );
}

export default NewPasswordChanges;
