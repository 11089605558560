import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import MainComponent from "./MainComponent";
import Login from "../Login/Login";
import Signup from "../Login/SignUp";
import ForgotPassword from "../Login/ForgotPassword";
import Logout from "../Login/Logout";
import ItemDetail from "./ItemDetail";
import HashtagList from "../Hashtags/sub-components/HashtagList";
// import HashtagComponent from "./HashtagComponent";
import HashtagTasksPage from "../Hashtags/sub-components/HashtagTasksPage";
import VerifyOTP from "../Login/VerifyOTP";
import NewPasswordchanges from "../Login/NewPasswordChanges";
import GanttChartView from '../Timeline/GanttChartView';

const AppRoutes = ({ isUserLoggedIn, ...props }) => {
  // const selectedHashtags = useSelector(
  //   (state) => state.hashtags.selectedHashtags
  // );

  return (
    <Routes>
      {isUserLoggedIn ? (
        <>
          <Route path="/" element={<Navigate replace to="/main" />} />
          <Route path="/main/*" element={<MainComponent {...props} />} />
          <Route path="/main" element={<MainComponent {...props} />} />
          <Route path="/main" element={<HashtagList />} />
          <Route
            path="/hashtags/:id"
            element={<HashtagTasksPage {...props} />}
          />
          <Route path="/task/:itemId" element={<ItemDetail {...props} />} />
          <Route path="/timeline" element={<GanttChartView />} />
          <Route path="/logout" element={<Logout />} />

        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verifyotp" element={<VerifyOTP />}></Route>
          <Route
            path="/newpasswordchanges"
            element={<NewPasswordchanges />}
          ></Route>
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;


// import React from "react";
// import { useSelector } from "react-redux";
// import { Route, Routes, Navigate } from "react-router-dom";
// import MainComponent from "./MainComponent";
// // import HashtagComponent from "./HashtagComponent";
// import Login from "../Login/Login";
// import Signup from "../Login/SignUp";
// import ForgotPassword from "../Login/ForgotPassword";
// import Logout from "../Login/Logout";
// import ItemDetail from "./ItemDetail";
// // import { fetchItem } from "../Tasks/services/ItemServices";
// import UserProfile from "../Login/UserProfile";
// import VerifyOTP from "../Login/VerifyOTP";
// import HashtagList from "../Hashtags/sub-components/HashtagList";
// import ResetPassword from "../Login/ResetPassword";
// const AppRoutes = ({ isUserLoggedIn, ...props }) => {
//   const selectedHashtags = useSelector(
//     (state) => state.hashtags.selectedHashtags
//   );

//   return (
//     <Routes>
//       {isUserLoggedIn ? (
//         <>
//           <Route path="/" element={<Navigate replace to="/main" />} />
//           <Route path="/main/*" element={<MainComponent {...props} />} />
//           <Route path="/main" element={<MainComponent {...props} />} />
//           <Route path="/main" element={<HashtagList />} />
//           {/* <Route
//             path="/hashtags/:customIdPath"
//             element={<HashtagComponent {...props} />}
//           /> */}
//           <Route path="/task/:itemId" element={<ItemDetail {...props} />} />
//           <Route path="/logout" element={<Logout />} />
//           <Route path="/userprofile" element={<UserProfile />}></Route>
//           {/* Add other routes as needed */}
//         </>
//       ) : (
//         <>
//           <Route path="/" element={<Login />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/forgotpassword" element={<ForgotPassword />} />
//           <Route path="resetpassword" element={<ResetPassword />}></Route>
//           <Route path="/verifyotp" element={<VerifyOTP />}></Route>

//           {/* Add other routes as needed */}
//         </>
//       )}
//     </Routes>
//   );
// };
// export default AppRoutes;
