import React, { useEffect, useState } from "react";
import "../styles/HashtagAndItemManager.css";

function SelectedHashtagsDisplay({ hashtagIds }) {
  const uncategorizedHashtagId = process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID; // Define the ID here
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingHashtagId, setEditingHashtagId] = useState(null);
  const [editedTag, setEditedTag] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [selectedHashtags, setSelectedHashtags] = useState([]);

  // Fetch hashtags details based on the provided IDs
  useEffect(() => {
    if (hashtagIds) {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/hashtags/details?ids=${hashtagIds}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch hashtags");
          }
          return response.json();
        })
        .then((data) => {
          setHashtags(data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [hashtagIds]);

  const handleSelectHashtag = (hashtag) => {
    setSelectedHashtags((prevSelected) => {
      const isSelected = prevSelected.some((h) => h._id === hashtag._id);
      if (isSelected) {
        // Default to 'uncategorized' if deselecting the last selected hashtag
        if (prevSelected.length === 1) {
          return [{ _id: uncategorizedHashtagId, tag: "Uncategorized" }];
        } else {
          return prevSelected.filter((h) => h._id !== hashtag._id);
        }
      } else {
        // Remove 'uncategorized' if other hashtags are selected
        const filteredSelected = prevSelected.filter(
          (h) => h._id !== uncategorizedHashtagId
        );
        return [...filteredSelected, hashtag];
      }
    });
  };
  return (
    <div className="hashtag-manager-container">
      <h3>Selected Hashtags</h3>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">Error: {error}</p>}
      <ul className="hashtag-list">
        {hashtags.map((hashtag) => (
          <li
            key={hashtag._id}
            className={`hashtag-item ${
              selectedHashtags.some((selected) => selected._id === hashtag._id)
                ? "selected"
                : ""
            }`}
            onClick={() => handleSelectHashtag(hashtag)}
          >
            {hashtag.tag}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SelectedHashtagsDisplay;
