import React, { useState, useEffect } from "react";
import "./ActionPanel.css";

function AssigneeComponent({ itemId, assignee, handleSaveAssignee }) {
  const [users, setUsers] = useState([]);
  const [currentAssignee, setCurrentAssignee] = useState(assignee);
  const currentUsername = localStorage.getItem("username");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`);
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error("API call successful but response not OK.");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    setCurrentAssignee(assignee);
  }, [assignee]);

  const sendEmailNotification = async (newAssigneeUsername) => {
    const assigneeEmail = "purnima@transcent.in";
    const taskLink = `${window.location.origin}/task/${itemId}`;
    const emailPayload = {
      to: assigneeEmail,
      subject: `You have been assigned a new task`,
      body: `Task ID: <a href="${taskLink}">${itemId}</a> is assigned to ${newAssigneeUsername}. Please check your tasks for more details.`,
    };

    console.log("Email payload:", emailPayload); // Log to check the payload

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sendEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailPayload),
        }
      );
      if (!response.ok) {
        console.log("Failed to send email notification");
      }
    } catch (error) {
      console.error("Error sending email notification:", error);
    }
  };

  const updateAssignee = async (newAssigneeUsername) => {
    if (currentAssignee !== newAssigneeUsername) {
      try {
        if (typeof handleSaveAssignee !== "function") {
          throw new Error("handleSaveAssignee is not a function");
        }
        const taskLink = `${window.location.origin}/task/${itemId}`;
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/items/${itemId}/assignee`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              newAssignee: newAssigneeUsername,
              comment: `<a href="${taskLink}">Task ID: ${itemId}</a> is assigned to ${newAssigneeUsername}.`,
              user: localStorage.getItem("username"),
            }),
          }
        );

        if (!response.ok) {
          console.error("Failed to update assignee");
        } else {
          const updatedItem = await response.json();
          handleSaveAssignee(updatedItem);
          await sendEmailNotification(newAssigneeUsername);
          setCurrentAssignee(newAssigneeUsername);
          console.log("Assignee updated, comment posted, and email sent");
        }
      } catch (error) {
        console.error("Error during the updateAssignee process:", error);
      }
    }
  };

  return (
    <div>
      <label>Assignee:</label>
      <select
        value={currentAssignee}
        onChange={(e) => updateAssignee(e.target.value)}
        className="priority-box"
      >
        <option value="unassigned">unassigned</option>
        {users.map((user) => (
          <option key={user.username} value={user.username}>
            {user.username}
          </option>
        ))}
      </select>
    </div>
  );
}

export default AssigneeComponent;
