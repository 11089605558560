//HashtagManager.js
import React, { useState, useEffect } from "react";
import "../styles/HashtagAndItemManager.css";
import { useNavigate } from "react-router-dom";
function HashtagManager({ setSelectedHashtags }) {
  const uncategorizedHashtagId = process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID; // Define the ID here
  const [hashtags, setHashtags] = useState([]);
  const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]); // Local state for UI selection
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newHashtag, setNewHashtag] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [editingHashtag, setEditingHashtag] = useState(null);
  const [editedTag, setEditedTag] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  let navigate = useNavigate();
  // Fetch hashtags from the backend
  useEffect(() => {
    const fetchHashtags = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/hashtags`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setHashtags(data);

        // Default to 'uncategorized' if no hashtags are selected
        if (data.length > 0 && !uiSelectedHashtags.length) {
          setUiSelectedHashtags(
            data.filter((h) => h._id === uncategorizedHashtagId)
          );
        }
      } catch (err) {
        setError("Failed to fetch hashtags");
      }
      setLoading(false);
    };
    fetchHashtags();
  }, []);

  // Retrieve uiSelectedHashtags from local storage
  useEffect(() => {
    const savedHashtags = localStorage.getItem("uiSelectedHashtags");
    if (savedHashtags) {
      setUiSelectedHashtags(JSON.parse(savedHashtags));
    } else if (hashtags.length > 0) {
      // If nothing is stored and hashtags are loaded, set to uncategorized
      setUiSelectedHashtags(
        hashtags.filter((h) => h._id === uncategorizedHashtagId)
      );
    }
  }, [hashtags]);
  // Update local storage when uiSelectedHashtags change
  useEffect(() => {
    localStorage.setItem(
      "uiSelectedHashtags",
      JSON.stringify(uiSelectedHashtags)
    );
    setSelectedHashtags(uiSelectedHashtags);
  }, [uiSelectedHashtags, setSelectedHashtags]);

  // Update the selected hashtags in the parent component
  //   useEffect(() => {
  //     setSelectedHashtags(uiSelectedHashtags);
  //   }, [uiSelectedHashtags, setSelectedHashtags]);

  const handleSelectHashtag = (hashtag) => {
    setUiSelectedHashtags((prev) => {
      const index = prev.findIndex((h) => h._id === hashtag._id);
      if (index >= 0) {
        const newHashtags = [...prev.slice(0, index), ...prev.slice(index + 1)];
        return newHashtags.length > 0
          ? newHashtags
          : [{ _id: uncategorizedHashtagId, tag: "Uncategorized" }];
      } else {
        return [
          ...prev.filter((h) => h._id !== uncategorizedHashtagId),
          hashtag,
        ];
      }
    });
  };

  // Update the form submit handler in both forms to use handleAddOrUpdateHashtag

  const handleAddOrUpdateHashtag = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null); // Reset error message

    const endpoint = editingHashtag
      ? `/api/hashtags/${editingHashtag}`
      : "/api/hashtags";
    const method = editingHashtag ? "PUT" : "POST";
    const body = JSON.stringify(
      editingHashtag
        ? { tag: editedTag, description: editedDescription }
        : { tag: newHashtag, description: newDescription }
    );

    try {
      const response = await fetch(endpoint, {
        method: method,
        headers: { "Content-Type": "application/json" },
        body: body,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedHashtag = await response.json();
      if (editingHashtag) {
        setHashtags(
          hashtags.map((h) => (h._id === editingHashtag ? updatedHashtag : h))
        );
        setEditingHashtag(null);
      } else {
        setHashtags([...hashtags, updatedHashtag]);
        setNewHashtag("");
        setNewDescription("");
      }
    } catch (err) {
      setError("Failed to update hashtag");
    }
    setLoading(false);
  };

  // Function to start editing a hashtag
  // Add setError(null) in startEditHashtag to clear errors when starting to edit
  const startEditHashtag = (hashtag) => {
    setError(null); // Reset error message
    setEditingHashtag(hashtag._id);
    setEditedTag(hashtag.tag);
    setEditedDescription(hashtag.description);
  };

  // Function to delete a hashtag
  const deleteHashtag = async (id) => {
    setLoading(true);
    setError(null); // Reset error message
    try {
      const response = await fetch(`/api/hashtags/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setHashtags(hashtags.filter((hashtag) => hashtag._id !== id));
    } catch (error) {
      setError("Error deleting hashtag");
    }
    setLoading(false);
  };

  return (
    <div className="hashtag-manager-container">
      <h3>Hashtags</h3>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">Error: {error}</p>}

      <ul className="hashtag-list">
        {hashtags.map((hashtag) => (
          <li
            key={hashtag._id}
            className={`hashtag-item ${
              uiSelectedHashtags.some(
                (selected) => selected._id === hashtag._id
              )
                ? "selected"
                : ""
            }`}
            onClick={() => handleSelectHashtag(hashtag)}
          >
            {hashtag.tag}
          </li>
        ))}
      </ul>

      <div className="form-buttons">
        <form className="hashtag-form" onSubmit={handleAddOrUpdateHashtag}>
          <div className="form">
            <input
              type="text"
              value={editingHashtag ? editedTag : newHashtag}
              onChange={(e) =>
                editingHashtag
                  ? setEditedTag(e.target.value)
                  : setNewHashtag(e.target.value)
              }
              placeholder={editingHashtag ? "Edit Hashtag" : "New Hashtag"}
            />
            <input
              type="text"
              value={editingHashtag ? editedDescription : newDescription}
              onChange={(e) =>
                editingHashtag
                  ? setEditedDescription(e.target.value)
                  : setNewDescription(e.target.value)
              }
              placeholder="Description"
            />
            <button className="button-fullwidth" type="submit">
              {editingHashtag ? "Update Hashtag" : "Add Hashtag"}
            </button>
          </div>
        </form>
        {uiSelectedHashtags.length === 1 && (
          <button
            className="button-linkstyle"
            onClick={() => startEditHashtag(uiSelectedHashtags[0])}
          >
            Edit Selected Hashtag
          </button>
        )}
        {uiSelectedHashtags.length > 0 &&
          false && ( // Added a 'false' condition to prevent rendering
            <button
              className="button-linkstyle"
              onClick={() =>
                deleteHashtag(uiSelectedHashtags.map((h) => h._id))
              }
            >
              Delete Selected Hashtag(s)
            </button>
          )}
      </div>
    </div>
  );
}
export default HashtagManager;

// import React, { useState, useEffect } from "react";
// import HashtagService from "../services/HashtagServices"; // Adjust the import path accordingly
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { setSelectedHashtags as setReduxSelectedHashtags } from "../../../redux/actions"; // Adjust the import path accordingly
// import "../styles/HashtagManager.css";
// function HashtagManager({ setSelectedHashtags, initialHashtags }) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [hashtags, setHashtags] = useState([]);
//   const [selectedHashtagsState, setSelectedHashtagsState] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [newHashtag, setNewHashtag] = useState("");
//   const [newDescription, setNewDescription] = useState("");
//   const [editingHashtag, setEditingHashtag] = useState(null);

//   useEffect(() => {
//     if (!initialHashtags || initialHashtags.length === 0) {
//       setLoading(true);
//       HashtagService.fetchHashtags()
//         .then((fetchedHashtags) => {
//           setHashtags(fetchedHashtags);
//           setLoading(false);
//         })
//         .catch((error) => {
//           setError("Error fetching hashtags");
//           setLoading(false);
//         });
//     } else {
//       setHashtags(initialHashtags);
//     }
//   }, [initialHashtags]);

//   const handleSelectHashtag = (hashtag) => {
//     const updatedSelection = selectedHashtagsState.some(
//       (h) => h.customId === hashtag.customId
//     )
//       ? selectedHashtagsState.filter((h) => h.customId !== hashtag.customId)
//       : [...selectedHashtagsState, hashtag];

//     setSelectedHashtagsState(updatedSelection);
//     dispatch(setReduxSelectedHashtags(updatedSelection));
//     setSelectedHashtags(updatedSelection);
//     const customIdPath = updatedSelection.map((ht) => ht.customId).join(",");
//     navigate(`/main/${customIdPath}`);

//     // const customIdPath = updatedSelection.map((ht) => ht.customId).join(",");
//   };

//   const handleAddOrUpdateHashtag = async (event) => {
//     event.preventDefault();
//     setLoading(true);
//     try {
//       const hashtagData = { tag: newHashtag, description: newDescription };
//       const savedHashtag = editingHashtag
//         ? await HashtagService.updateHashtag(
//             editingHashtag.customId,
//             hashtagData
//           )
//         : await HashtagService.createHashtag(hashtagData);

//       const updatedHashtags = editingHashtag
//         ? hashtags.map((ht) =>
//             ht.customId === savedHashtag.customId ? savedHashtag : ht
//           )
//         : [...hashtags, savedHashtag];

//       setHashtags(updatedHashtags);
//       setNewHashtag("");
//       setNewDescription("");
//       setEditingHashtag(null);
//       setError(null);
//     } catch (error) {
//       setError("Error saving hashtag");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleEditHashtag = (hashtag) => {
//     setEditingHashtag(hashtag);
//     setNewHashtag(hashtag.tag);
//     setNewDescription(hashtag.description);
//   };

//   return (
//     <div className="hashtag-manager-container">
//       <h3>Hashtags</h3>
//       {loading && <p className="loading">Loading...</p>}
//       {error && <p className="error">Error: {error}</p>}
//       <ul className="hashtag-list">
//         {hashtags.map((hashtag) => (
//           <li
//             key={hashtag.customId}
//             className={`hashtag-item ${
//               selectedHashtagsState.some(
//                 (selected) => selected.customId === hashtag.customId
//               )
//                 ? "selected"
//                 : ""
//             }`}
//             onClick={() => handleSelectHashtag(hashtag)}
//           >
//             {hashtag.tag}
//           </li>
//         ))}
//       </ul>
//       <form onSubmit={handleAddOrUpdateHashtag} className="hashtag-form">
//         <input
//           type="text"
//           value={newHashtag}
//           onChange={(e) => setNewHashtag(e.target.value)}
//           placeholder="Hashtag"
//           className="input-hashtag"
//         />
//         <input
//           type="text"
//           value={newDescription}
//           onChange={(e) => setNewDescription(e.target.value)}
//           placeholder="Description"
//           className="input-description"
//         />
//         <button type="submit" className="submit-button">
//           {editingHashtag ? "Update Hashtag" : "Add Hashtag"}
//         </button>
//       </form>
//       {editingHashtag && (
//         <button
//           onClick={() => setEditingHashtag(null)}
//           className="cancel-edit-button"
//         >
//           Cancel Edit
//         </button>
//       )}
//     </div>
//   );
// }

// export default HashtagManager;
