import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./ActionPanel.css";

function HashtagComponent({ selectedItem, onSaveHashtags }) {
  const [availableHashtags, setAvailableHashtags] = useState([]);
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetch("/api/hashtags")
      .then((response) => response.json())
      .then((data) => {
        const formattedData = data.map((ht) => ({
          value: ht._id,
          label: ht.tag,
          customId: ht.customId,
          link: `/hashtags/${ht.customId}`,
          title: ht.tag, // Add title attribute for tooltip
        }));
        setAvailableHashtags(formattedData);

        const defaultHashtag = formattedData.find(
          (ht) => ht.label === "Uncategorized"
        );
        if (defaultHashtag) {
          setSelectedHashtags([defaultHashtag]);
        }
      })
      .catch((error) => console.error("Error fetching hashtags:", error));
  }, []);

  useEffect(() => {
    if (selectedItem?.itemId) {
      fetch(`/api/items/${selectedItem.itemId}/hashtags`)
        .then((response) => response.json())
        .then((data) => {
          const formattedData = data.map((ht) => ({
            value: ht._id,
            label: ht.tag,
            customId: ht.customId,
            title: ht.tag, // Add title attribute for tooltip
          }));
          setSelectedHashtags(formattedData);
        })
        .catch((error) =>
          console.error("Error fetching item hashtags:", error)
        );
    }
  }, [selectedItem]);

  const handleChange = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      const defaultHashtag = availableHashtags.find(
        (ht) => ht.label === "Uncategorized"
      );
      if (defaultHashtag) {
        selectedOptions = [defaultHashtag];
        setSelectedHashtags(selectedOptions);
      }
    } else {
      setSelectedHashtags(selectedOptions);
    }

    if (selectedItem && selectedItem.itemId) {
      const updatedHashtagIds = selectedOptions.map((ht) => ht.value);
      const username = localStorage.getItem("username");

      fetch(`/api/items/${selectedItem.itemId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hashtags: updatedHashtagIds,
          user: username,
        }),
      })
        .then((response) => response.json())
        .then(() => {
          onSaveHashtags(selectedOptions);
          setSuccessMessage("Hashtags updated successfully!");
          setTimeout(() => setSuccessMessage(""), 3000);
        })
        .catch((error) => {
          console.error("Error updating hashtags:", error);
          setSuccessMessage("Failed to update hashtags.");
        });
    }
  };

  return (
    <div>
      <label htmlFor="hashtag-select">Hashtags:</label>
      <Select
        id="hashtag-select"
        options={availableHashtags}
        isMulti
        value={selectedHashtags}
        className="priority-box"
        onChange={handleChange}
        classNamePrefix="react-select-custom"
        getOptionLabel={(option) => (
          <div title={option.title}>{option.label}</div>
        )}
      />
    </div>
  );
}

export default HashtagComponent;

// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import "./ActionPanel.css";
// // import { Link } from "react-router-dom";

// function HashtagComponent({ selectedItem, onSaveHashtags }) {
//   const [availableHashtags, setAvailableHashtags] = useState([]);
//   const [selectedHashtags, setSelectedHashtags] = useState([]);
//   const [successMessage, setSuccessMessage] = useState("");

//   useEffect(() => {
//     fetch("/api/hashtags")
//       .then((response) => response.json())
//       .then((data) => {
//         const formattedData = data.map((ht) => ({
//           value: ht._id,
//           label: ht.tag,
//           customId: ht.customId,
//           link: `/hashtags/${ht.customId}`,
//         }));
//         setAvailableHashtags(formattedData);

//         // Set a default hashtag initially if needed
//         const defaultHashtag = formattedData.find(
//           (ht) => ht.label === "Uncategorized"
//         );
//         if (defaultHashtag) {
//           setSelectedHashtags([defaultHashtag]);
//         }
//       })
//       .catch((error) => console.error("Error fetching hashtags:", error));
//   }, []);

//   useEffect(() => {
//     if (selectedItem?.itemId) {
//       fetch(`/api/items/${selectedItem.itemId}/hashtags`)
//         .then((response) => response.json())
//         .then((data) => {
//           const formattedData = data.map((ht) => ({
//             value: ht._id,
//             label: ht.tag,
//             customId: ht.customId,
//             // link: `/hashtags/${ht.customId}`,
//           }));
//           setSelectedHashtags(formattedData);
//         })
//         .catch((error) =>
//           console.error("Error fetching item hashtags:", error)
//         );
//     }
//   }, [selectedItem]);

//   const handleChange = (selectedOptions) => {
//     if (selectedOptions.length === 0) {
//       // Automatically select the default hashtag when all selections are removed
//       const defaultHashtag = availableHashtags.find(
//         (ht) => ht.label === "Uncategorized"
//       );
//       if (defaultHashtag) {
//         selectedOptions = [defaultHashtag];
//         setSelectedHashtags(selectedOptions);
//       }
//     } else {
//       setSelectedHashtags(selectedOptions);
//     }

//     if (selectedItem && selectedItem.itemId) {
//       const updatedHashtagIds = selectedOptions.map((ht) => ht.value);
//       const username = localStorage.getItem("username");

//       fetch(`/api/items/${selectedItem.itemId}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           hashtags: updatedHashtagIds,
//           user: username,
//         }),
//       })
//         .then((response) => response.json())
//         .then(() => {
//           onSaveHashtags(selectedOptions);
//           // Update the UI or trigger further actions as necessary
//           setSuccessMessage("Hashtags updated successfully!");
//           setTimeout(() => setSuccessMessage(""), 3000);
//         })
//         .catch((error) => {
//           console.error("Error updating hashtags:", error);
//           setSuccessMessage("Failed to update hashtags.");
//         });
//     }
//   };

//   return (
//     <div>
//       <label htmlfor="hashtag-select">Hashtags:</label>
//       <Select
//         id="hashtag-select"
//         options={availableHashtags}
//         isMulti
//         value={selectedHashtags}
//         className="priority-box"
//         onChange={handleChange}
//         classNamePrefix="react-select-custom"
//       />
//     </div>
//   );
// }

// export default HashtagComponent;
