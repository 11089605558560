import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import './GanttChartView.css'; // Import the CSS file for styling

interface Task {
  itemId: string;
  name: string;
  description: string;
  start_date: string | null;
  end_date: string | null;
  priority: string;
  status: string;
  assignee: string;
  hashtags: string[];
}

interface Hashtag {
  customId: string;
  tag: string;
}

interface FilterValues {
  statuses: string[];
  assignees: string[];
  priorities: string[];
  hashtags: { value: string; label: string }[]; // Adjusted to hold both label and value
}

const GanttChartView: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    statuses: [],
    assignees: [],
    priorities: [],
    hashtags: [] // Initialized as empty array
  });
  const [filters, setFilters] = useState({
    status: '',
    assignee: '',
    hashtags: '',
    priority: '',
  });
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' | null }>({ key: '', direction: null });

  useEffect(() => {
    const fetchFilterValues = async () => {
      try {
        const response = await axios.get('/api/unique-values');
        const { hashtags, statuses, assignees, priorities } = response.data;

        const hashtagOptions = hashtags.map((h: Hashtag) => ({
          value: h.customId, // Accessing customId from hashtag
          label: h.tag       // Accessing tag from hashtag
        }));

        setFilterValues({
          statuses,
          assignees,
          priorities,
          hashtags: hashtagOptions // Set the processed hashtag options
        });
      } catch (error) {
        console.error('Failed to fetch filter values', error);
      }
    };

    fetchFilterValues();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('/api/items/filtered', { params: filters });
        console.log('Fetched data:', response.data); // Logging the response for debugging
        if (Array.isArray(response.data)) {
          setTasks(response.data);
        } else {
          setError('Fetched data is not an array');
          console.error('Fetched data is not an array', response.data);
        }
      } catch (error) {
        setError('Failed to fetch tasks');
        console.error('Failed to fetch tasks', error);
      }
    };

    fetchTasks();
  }, [filters]);  // This useEffect will now handle all task fetching logic

  const filteredTasks = tasks.filter(task => {
    return (!filters.status || task.status === filters.status) &&
           (!filters.assignee || task.assignee === filters.assignee) &&
           (!filters.priority || task.priority === filters.priority) &&
           (filters.hashtags.length === 0 || task.hashtags.some(hashtag => filters.hashtags.includes(hashtag)));
  });

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' | null = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedTasks = React.useMemo(() => {
    let sortableTasks = [...filteredTasks];
    if (sortConfig.key && sortConfig.direction) {
      sortableTasks.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof Task];
        const bValue = b[sortConfig.key as keyof Task];
        if (aValue == null || bValue == null) return 0; // Handle null values
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableTasks;
  }, [filteredTasks, sortConfig]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const groupedTasks = tasks.reduce((acc, task) => {
    task.hashtags.forEach((hashtag) => {
      if (!acc[hashtag]) {
        acc[hashtag] = [];
      }
      acc[hashtag].push(task);
    });
    return acc;
  }, {} as Record<string, Task[]>);

  const chartData = [
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
      { type: 'string', label: 'Priority' },
      { type: 'string', label: 'Status' },
    ],
    ...tasks
      .filter(task => task.start_date && task.end_date)
      .map(task => {
        const startDate = task.start_date ? new Date(task.start_date) : null;
        const endDate = task.end_date ? new Date(task.end_date) : null;
        console.log('Processing task:', {
          id: task.itemId,
          description: task.description,
          startDate,
          endDate,
          priority: task.priority,
          status: task.status,
        });
        return [
          task.itemId,
          task.description,
          startDate,
          endDate,
          null, // Duration is null because start and end dates are used
          null, // Percent Complete is not provided
          null, // No dependencies specified
          task.priority,
          task.status,
        ];
      }),
  ];

  console.log('Chart data:', chartData);

  const options = {
    height: 400,
    gantt: {
      trackHeight: 30,
    },
  };

  interface ChartSelectionItem {
    row: number;
    column?: number;
  }
  
  const handleChartClick = ({ chartWrapper }: { chartWrapper: any }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length > 0) {
      const selectedItem = selection[0]; // Get the selected item
      if (selectedItem.row != null) {
        const taskId = chartData[selectedItem.row + 1][0];  // Get the taskId from the chartData (adjust for header row)
        window.open(`/task/${taskId}`, '_blank', 'noopener,noreferrer'); // Open in new tab
      }
    }
  };

  return (
    <div className="gantt-container">
      <h1>Task Timeline</h1>
      <div className="filters">
        <label>
          Status:
          <select name="status" value={filters.status} onChange={handleFilterChange}>
            <option value="">All</option>
            {filterValues.statuses.map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </label>
        <label>
          Assignee:
          <select name="assignee" value={filters.assignee} onChange={handleFilterChange}>
            <option value="">All</option>
            {filterValues.assignees.map(assignee => (
              <option key={assignee} value={assignee}>{assignee}</option>
            ))}
          </select>
        </label>
        <label>
          Hashtags:
          <select name="hashtags" value={filters.hashtags} onChange={handleFilterChange}>
            <option value="">All</option>
            {filterValues.hashtags.map(hashtag => (
              <option key={hashtag.value} value={hashtag.value}>{hashtag.label}</option>
            ))}
          </select>
        </label>
        <label>
          Priority:
          <select name="priority" value={filters.priority} onChange={handleFilterChange}>
            <option value="">All</option>
            {filterValues.priorities.map(priority => (
              <option key={priority} value={priority}>{priority}</option>
            ))}
          </select>
        </label>
      </div>
      <div className="gantt-chart-wrapper" style={{ height: '60vh', overflowY: 'scroll' }}>
        <Chart
          chartType="Gantt"
          width="100%"
          height="100%"
          data={chartData}
          options={options}
          loader={<div>Loading Chart...</div>}
          chartEvents={[
            {
              eventName: 'select',
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length > 0 && selection[0].row != null) {
                  handleChartClick(selection[0].row);  // Pass the row index directly
                }
              }
            }
          ]}
        />
      </div>

      <div className="tasks-without-dates">
        <h2>Tasks Without Start and End Dates</h2>
        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('itemId')}>
                ID {sortConfig.key === 'itemId' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => handleSort('name')}>
                Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => handleSort('description')}>
                Description {sortConfig.key === 'description' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => handleSort('status')}>
                Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => handleSort('priority')}>
                Priority {sortConfig.key === 'priority' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
              <th onClick={() => handleSort('assignee')}>
                Assignee {sortConfig.key === 'assignee' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedTasks
              .filter(task => !task.start_date && !task.end_date && task.status !== 'completed')
              .map(task => (
                <tr key={task.itemId}>
                  <td><a href={`/task/${task.itemId}`} target="_blank" rel="noopener noreferrer">{task.itemId}</a></td>
                  <td>{task.name}</td>
                  <td>{task.description || 'No description available'}</td>
                  <td>{task.status}</td>
                  <td>{task.priority}</td>
                  <td>{task.assignee}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GanttChartView;
