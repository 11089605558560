import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/HashtagManager.css";

function HashtagList({ setSelectedHashtags }) {
  const uncategorizedHashtagId = process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID;
  const navigate = useNavigate();
  const [hashtags, setHashtags] = useState([]);
  const [uiSelectedHashtags, setUiSelectedHashtags] = useState([
    uncategorizedHashtagId,
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!uncategorizedHashtagId) {
      console.error(
        "Uncategorized hashtag ID not found in environment variables"
      );
    } else {
      console.log("Initial selected hashtag:", [uncategorizedHashtagId]);
    }
  }, [uncategorizedHashtagId]);

  useEffect(() => {
    setLoading(true);
    fetch("/api/hashtags")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setHashtags(data);
        console.log("Fetched hashtags:", data);
      })
      .catch((err) => {
        setError("Failed to fetch hashtags");
        console.error("Error fetching hashtags:", err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSelectHashtag = (selectedHashtag) => {
    if (!selectedHashtag || typeof setSelectedHashtags !== "function") {
      console.error(
        "Invalid hashtag selected or setSelectedHashtags is not a function"
      );
      return;
    }

    setUiSelectedHashtags((prevSelected) => {
      const isSelected = prevSelected.includes(selectedHashtag._id);
      const newSelected = isSelected
        ? prevSelected.filter((id) => id !== selectedHashtag._id)
        : [
            ...prevSelected.filter((id) => id !== uncategorizedHashtagId),
            selectedHashtag._id,
          ];

      if (newSelected.length === 0) {
        newSelected.push(uncategorizedHashtagId);
      }

      setSelectedHashtags(newSelected);
      navigate(`/main/hashtag/${encodeURIComponent(selectedHashtag._id)}`);
      return newSelected;
    });
  };

  useEffect(() => {
    if (typeof setSelectedHashtags === "function") {
      setSelectedHashtags(uiSelectedHashtags);
    } else {
      console.error("setSelectedHashtags is not a function");
    }
  }, [uiSelectedHashtags, setSelectedHashtags]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="hashtag-manager-container">
      <h3>All Hashtags</h3>
      <ul className="hashtag-list">
        {hashtags.map((hashtag) => (
          <li
            key={hashtag._id}
            onClick={() => handleSelectHashtag(hashtag)}
            className={
              uiSelectedHashtags.includes(hashtag._id) ? "selected" : ""
            }
          >
            {hashtag.tag}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HashtagList;
