import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function VerifyOtp() {
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};

  const inputStyle = {
    padding: "10px",
    margin: "0 0 20px 0",
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
    width: "calc(100% - 22px)",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    padding: "12px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background 0.3s",
    pointerEvents: "auto",
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/verifyotp`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ emailOrPhone: email, otp }),
    });
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      navigate("/newpasswordchanges", {
        state: { emailOrPhone: email.toLowerCase().trim() },
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        marginLeft: "250px",
        width: "50vw",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          borderRadius: "8px",
          background: "white",
          boxSizing: "border-box",
        }}
      >
        <form
          onSubmit={handleVerifyOtp}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h1 style={{ textAlign: "center" }}>Verify OTP</h1>
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "inline-block",
                width: "100%",
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Enter OTP:
            </label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              style={inputStyle}
              required
            />
          </div>
          <button type="submit" style={buttonStyle}>
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}

export default VerifyOtp;
